/// <reference path='./index.d.ts' />

import { debounce } from 'lodash';

import { dispatch } from '../../../lib/pork';
import { constructAction } from '../util';
import { NoteDataStore } from './index';
import { interviewApi } from '../../api';
import axios from 'axios';
import * as appActions from '../../stores/app/actions';
import { ErrorCallback } from 'async';
import { interviewActionType } from '../discovery/actions';

const buildActionType = (subName: string): string => {
    return `${NoteDataStore.namespace}.${subName}`;
};

// fetch the note data from the API and return an action to dispatch.
export function loadNoteData(interviewID: string, dataURL: string, callback: (error?, action?) => void): void {
    const type = buildActionType('loadNoteData');

    appActions.setIsFetchingNotes(true);

    interviewApi.getNotesArtifactData(dataURL, (error, response) => {
        const notes = { interviewID, data: response.text };
        const action = constructAction(type, notes);

        // Also update our store
        updateInStore(interviewID, response.text);

         // Alert our service worker to the change
    
        appActions.setIsFetchingNotes(false);
        dispatch(action);

        return callback(error, action);
    });
}

export function loadNoteDataSync(interviewID: string, dataURL: string, callback: (data: any) => void): void {
    try {
        axios.get(dataURL, { withCredentials: false }).then((response) => {
            try {
                //@ts-ignore
                const _response = JSON.stringify(response.data);

                callback({interviewID, success: true, data: _response});
            }
            catch (e) {
                callback({interviewID, success: false, data: e});
            }
        }, (error) => {
            callback({interviewID, success: false, data: error});
        }).catch((error) => {
            callback({interviewID, success: false, data: error});
        })
    }
    catch (e) {
        callback({interviewID, success: false, data: e});
    }
}

// for optimistic local cache updating. Helps to keep react components in sync with their stores.
export function updateInStore(interviewID: string, data: string, callback?: ErrorCallback): void {
    const type = buildActionType('edit');

    const notes = { interviewID, data };
    const action = constructAction(type, notes);

    action.meta = { silent: true }; // we don't want to update the view layer too often here

    dispatch(action);
}

// Edit the note with id `noteID` and body `data`. Used in conjunction with updateNoteInStore.
export function updateInAPI(interviewID: string, data: string, previousHash: string, callback?: ErrorCallback): void {
    const type = buildActionType('edit');

    interviewApi.updateNotes(interviewID, data, previousHash, (error, response) => {
        // Note: we don't locally save the response, because we already have the info,
        // and by now the user has updated the note more and we don't want to overwrite it.
        if (response === undefined) {
            appActions.setShowConflictPrompt(true, "");
        }
        else {
            // Dispatch an action to update our version change
            const newVersionCache = response.body.versionCache;

            dispatch(
                constructAction(interviewActionType('updateVersionCache'),{
                    interviewID, 
                    versionCache: newVersionCache
                })
            );
        }

        if (error) {
            dispatch(constructAction(type, error, true));
            if (callback) callback(error);
            return;
        }

        if (callback) return callback();
    });
}

export function updateInAPIWorker(interviewID: string, data: string, previousHash: string, user: User, callback?: ErrorCallback): void {
    const type = buildActionType('edit');

    interviewApi.updateNotesWorker(interviewID, data, user, previousHash, (error, response) => {
        // Note: we don't locally save the response, because we already have the info,
        // and by now the user has updated the note more and we don't want to overwrite it.
        if (response === undefined) {
            appActions.setShowConflictPrompt(true, "");
        }
        else {
            // Dispatch an action to update our version change
            const newVersionCache = response.body.versionCache;

            dispatch(
                constructAction(interviewActionType('updateVersionCache'),{
                    interviewID, 
                    versionCache: newVersionCache
                })
            );
        }

        if (error) {
            dispatch(constructAction(type, error, true));
            if (callback) callback(error);
            return;
        }

        if (callback) return callback();
    });
}
