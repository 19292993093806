import { dispatch } from '../../../lib/pork';

import { constructAction } from '../util';

import { CanvasStore } from './index';
import * as appActions from '../../stores/app/actions';
import {canvasAPI} from '../../api';

let canvasNamespace = CanvasStore.namespace;

export function getCanvas(groupID: string, callback?: (data) => void) {
    let type = 'getCanvas';
    let type2 = 'clearCanvasName';

    dispatch(constructAction(type2, ''));

    canvasAPI.getCanvas(groupID, (error, response) => {
        appActions.setNubMap(response.body);

        if (error) return dispatch(constructAction(type, error, true));

        if (callback) {
            callback(response.body);
        }

        dispatch(constructAction(type, response.body));
    });
}

export function recieveCanvasPayload(payload: any) {
    let type = 'setCanvasPayload';

    dispatch(constructAction(type, payload));
}

export function getCanvasBase(groupID: string, callback?: (data) => void) {
    let type = 'getCanvasBase';

    canvasAPI.getCanvasBase(groupID, (error, response) => {
        if (error) return dispatch(constructAction(type, error, true));

        if (callback) {
            callback(response.body);
        }

        dispatch(constructAction(type, response.body));
    });
}

export function setCanvasEntries(payload) {
    
}

export function getCanvasEntries(groupID: string, callback?: (data) => void) {
    let type = 'getCanvasEntries';

    canvasAPI.getCanvasEntries(groupID, (error, response) => {
        appActions.setNubMap(response.body);
        
        if (error) return dispatch(constructAction(type, error, true));

        if (callback) {
            callback(response.body);
        }

        dispatch(constructAction(type, response.body));
    });
}

export function getCanvasStates(groupID: string, callback?: (data) => void) {
    let type = 'getCanvasStates';

    canvasAPI.getCanvasStates(groupID, (error, response) => {
        if (error) return dispatch(constructAction(type, error, true));

        if (callback) {
            callback(response.body);
        }

        dispatch(constructAction(type, response.body));
    });
}

// Get canvas but without any dispatching events
export function getCanvasRaw(groupID: string, callback?: (data) => void) {
    canvasAPI.getCanvas(groupID, (error, response) => {
        if (callback) {
            callback(response.body);
        }
    });
}

export function updateCanvasDetails(groupID: string, details: CanvasAPI.PUTDetails) {
    const type = `${canvasNamespace}.getCanvas`;
    dispatch(constructAction(type, details));
    canvasAPI.putCanvas(groupID, details, (error, response) => {
        if (error) return dispatch(constructAction(type, error, true));
    });
}

export function updateEntries(groupID: string, categoryID: string, entryIDs: string[]) {
    let type = `${canvasNamespace}.reorderEntries`;
    canvasAPI.reorderEntries(groupID, entryIDs.join(','), (error, response) => {
        if (error) return dispatch(constructAction(type, error, true));

        dispatch(constructAction(type, {categoryID, entryIDs}));
    });
}

export function reorderEntries(dragIndex: number, hoverIndex: number, entryID: string, groupID: string, categoryID: string, entryIDs: string[]) {
    const type = `${canvasNamespace}.reorderEntries`;

    // Get a mutable copy of the entryIDs so we can change its order
    entryIDs = entryIDs.asMutable();

    // Using the entryID of where the entry is now, make sure that it's actually in our sortable list
    if (!entryIDs.includes(entryID)) return false;

    // The drag index is the location in the array we want to be at.  However if the user does not drop the object
    // while continuing to drag it across multiple locations only the index on the prop and not the ID will update
    // so we need to check and get the latest ID for a specified location
    const currentLocationID = entryIDs[dragIndex];

    // Now we want to remove that ID from our list of IDs
    entryIDs.splice(dragIndex, 1);

    // Now depending on whether or not it should be inserted before or after what the target location is
    // Simulating the direction of up and down
    // Insert our previously removed currentLocationID back into the array
    entryIDs.splice(hoverIndex, 0, currentLocationID);

    dispatch(constructAction(type, {categoryID, entryIDs}));
}

export function selectActionIndex(selectedAction: string) {
    const type = `${canvasNamespace}.selectActionIndex`;
    dispatch(constructAction(type, selectedAction));
}

export function selectWeekIndex(selectedWeek: string) {
    const type = `${canvasNamespace}.selectWeekIndex`;
    dispatch(constructAction(type, selectedWeek));
}

export function selectLockedHypothesis(selectedHypothesis: string) {
    const type = `${canvasNamespace}.selectLockedHypothesis`;
    dispatch(constructAction(type, selectedHypothesis));
}
