import { atom } from 'jotai';
import { atomWithLocalStorage } from './comments';
import axios from 'axios';

interface canvasStateInterface {
    commentEntry: string;
    insightsEntry: any;
    layout: any;
    library: any;
    canvas_filter_showInvalidated: boolean;
    canvas_filter_showValidated: boolean;
    canvas_filter_showUnresolved: boolean;
    contextEntry: string;
    selectedHypothesisIDToFilter: string;
}

export const INITIAL_CANVAS_ATOM_VALUE: canvasStateInterface = {
	commentEntry: undefined,
	insightsEntry: undefined,
	layout: "7bdd8112-11b4-11ed-ab60-8f1360fa7cc0",
	library: undefined,
	canvas_filter_showInvalidated: true,
	canvas_filter_showUnresolved: true,
	canvas_filter_showValidated: true,
	contextEntry: "",
	selectedHypothesisIDToFilter: "",
};

export const canvasAtom = atom(INITIAL_CANVAS_ATOM_VALUE);

export function canvasActionSetFilter1(setCanvasState: any, target: boolean) {
    setCanvasState((canvas) => {
        let c = { ...canvas }

        c['canvas_filter_showInvalidated'] = target;

        return c;
    });
}

export function canvasActionSetContext(setCanvasState: any, target: string) {
    setCanvasState((canvas) => {
        let c = { ...canvas }

        c['contextEntry'] = target;

        return c;
    });
}

export function canvasActionSetFilter2(setCanvasState: any, target: boolean) {
    setCanvasState((canvas) => {
        let c = { ...canvas }

        c['canvas_filter_showUnresolved'] = target;

        return c;
    });
}

export function canvasActionSetFilter3(setCanvasState: any, target: boolean) {
    setCanvasState((canvas) => {
        let c = { ...canvas }

        c['canvas_filter_showValidated'] = target;

        return c;
    });
}

export function canvasActionSetOpenComment(setCanvasState: any, target: any) {
    setCanvasState((canvas) => {
        let c = { ...canvas }

        c['commentEntry'] = target;

        return c;
    });
}

export function canvasActionSetOpenInsights(setCanvasState: any, target: any) {
    setCanvasState((canvas) => {
        let c = { ...canvas }

        c['insightsEntry'] = target;

        return c;
    });
}

export function canvasActionRecieveFindings(setCanvasState: any, findings: any) {
    setCanvasState((canvas) => {
        let c = { ...canvas }

        c['findings'] = findings;

        return c;
    });
}

export function canvasActionRecieveLibrary(setCanvasState: any, library: any) {
    setCanvasState((canvas) => {
        let c = { ...canvas }

        c['library'] = library;

        return c;
    });
}

export function canvasActionRecieveLayout(setCanvasState: any, layout: any) {
    setCanvasState((canvas) => {
        let c = { ...canvas }

        c['layout'] = layout;

        return c;
    });
}

export function loadLibraryExternally(setCanvasState) {
    axios.get(`${process.env.NEXT_PUBLIC_LINX_URL}/canvas_layouts`).then((res) => {
        setCanvasState((canvas) => {
            let c = { ...canvas }

            c['library'] = res.data;

            return c;
        });
    });
}

export const setSelectedHypothesisIDToFilter = (
    setCanvasState: Function,
    entryId: string
) => {
    setCanvasState((canvasState) => {
        return {
            ...canvasState,
            selectedHypothesisIDToFilter: entryId,
        };
    });
};
