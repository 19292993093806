import { atom } from 'jotai';

let fallBackOffLocalStorage = false;

function getAvailableStorageSpace() {
    let storageAvailable;
    try {
        storageAvailable = window.localStorage.remainingSpace;
    } catch (error) {
        storageAvailable = 0;
    }
    return storageAvailable;
}

// https://jotai.org/docs/guides/persistence#a-helper-function-with-local-storage-and-json-parse
export const atomWithLocalStorage = (key, initialValue, FIELDS_TO_NOT_PERSIST) => {
    const getInitialValue = () => {
        try {
            const item = localStorage.getItem(key)
            if (item !== null) {
                let payload = undefined;
                try {
                    payload = JSON.parse(item);

                    for (let i = 0; i < FIELDS_TO_NOT_PERSIST.length; i++) {
                        if (FIELDS_TO_NOT_PERSIST[i] in payload) {
                            payload[FIELDS_TO_NOT_PERSIST[i]] = initialValue[FIELDS_TO_NOT_PERSIST[i]];
                        }
                    }

                    return payload;
                }
                catch (e) {
                    return initialValue;
                }
            }
            return initialValue
        }
        catch (e) {
            return initialValue;
        }
    }
    const baseAtom = atom(getInitialValue())
    const derivedAtom = atom(
        (get) => get(baseAtom),
        (get, set, update) => {
            const nextValue =
                typeof update === 'function' ? update(get(baseAtom)) : update
            set(baseAtom, nextValue)

            if (fallBackOffLocalStorage === false) {
                try {
                    const requiredStorageSpace = JSON.stringify(nextValue).length;

                    if (requiredStorageSpace > getAvailableStorageSpace()) {
                        fallBackOffLocalStorage = true;
                        return;
                    }

                    localStorage.setItem(key, JSON.stringify(nextValue))
                }
                catch (e) {
                    // if we run out of space for storage fall back off trying to persist it
                    fallBackOffLocalStorage = true;
                }
            }
        }
    )
    return derivedAtom
}


export const INITIAL_COMMENTS_ATOM_VALUE = []

export const commentsAtom = atom(INITIAL_COMMENTS_ATOM_VALUE);
