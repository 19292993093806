import { atom } from 'jotai';
import axios from 'axios';
import { interviewApi } from '../../api';
import { atomWithLocalStorage } from './comments';

interface INTERVIEWStateInterface {
    id: string;

    interviewee?: string;
    company?: string;
    title?: string;
    email?: string;
    phone?: string;
    communication?: string;
    templateID?: string;

    status: string; // draft, published

    audio?: AudioArtifact;
    transcripts?: api.TranscriptArtifact[];
    notes?: api.NotesArtifact;
    user?: api.UserBase;

    hasUploaded: boolean;

    findings?: any[];
    selectedFinding: any;
    speakerDictionary?: any[];
    speakerLibrary?: any[];

    placeholderFinding: any;
}

export const INITIAL_INTERVIEW_ATOM_VALUE: INTERVIEWStateInterface = {
    id: '',
    hasUploaded: false,
    status: 'draft',
    speakerLibrary: [],
    speakerDictionary: [],
    selectedFinding: {},
    placeholderFinding: {}
}

export const interviewAtom = atom(INITIAL_INTERVIEW_ATOM_VALUE);

export function INTERVIEWClear(setINTERVIEWState, payload: any) {
    setINTERVIEWState((INTERVIEW) => {
        return {
            ...payload,
            speakerDictionary: INTERVIEW.speakerDictionary,
            speakerLibrary: INTERVIEW.speakerLibrary
        }
    });
}

export function setupDictionary(dictionary, set) {
    set((INTERVIEW) => {
        return {
            ...INTERVIEW,
            speakerDictionary: dictionary
        };
    });
}

// export function clearSelectedFinding(set) {
//     set((INTERVIEW) => {
//         return {
//             ...INTERVIEW,
//             selectedFinding: undefined,
//             placeholderFinding: {}
//         };
//     });
// }

export function setPlaceholderFinding(set, payload: any) {
    set((INTERVIEW) => {
        return {
            ...INTERVIEW,
            placeholderFinding: payload
        };
    });
}

export function selectFindingForInterview(set, payload: any) {
    set((INTERVIEW) => {
        return {
            ...INTERVIEW,
            selectedFinding: payload
        };
    });
}

export function setupLibraryAndDictionary(library, buffer, set) {
    set((i) => {
        return {
            ...i,
            speakerLibrary: buffer,
            speakerDictionary: library
        };
    });
}

export function setupLibrary(library, set) {
    set((INTERVIEW) => {
        return {
            ...INTERVIEW,
            speakerLibrary: library
        };
    });
}

export function loadFindingsForInterview(setCanvasState: any, interviewID: string) {
    axios.get(`${process.env.NEXT_PUBLIC_LINX_URL}/refactor/insights`, {
        params: {
            interviewID: interviewID
        }
    }).then((res) => {
        setCanvasState((canvas) => {
            let c = {...canvas}
    
            c['findings'] = res.data;
    
            return c;
        });
    })
}

export function loadInterview(setINTERVIEWState, interviewID) {
    // interviewApi.getInterview(interviewID, (error, response) => {
    //     axios.get('${process.env.NEXT_PUBLIC_LINX_URL}/refactor/insights', {
    //     params: {
    //         interviewID: interviewID
    //     }
    // }).then((res2) => {
    //     if (error) return console.error(error);
    //     // we'll fill this up with all the actions and return em.
    //     let actions = [];

    //     const interview: api.InterviewResponse = response.body;

    //     setINTERVIEWState((INTERVIEW) => {
    //         return {
    //             ...interview,
    //             findings: res2.data,
    //             placeholderFinding: {},
    //             selectedFinding: undefined,
    //             speakerDictionary: INTERVIEW.speakerDictionary,
    //             speakerLibrary: INTERVIEW.speakerLibrary
    //         }
    //     });
    // })

    // });
}
