 
import { each, has, clone, find, merge, indexOf, some } from 'lodash';
import * as appActions from './stores/app/actions';
import {IAvatar} from './lib/teacup';

interface IPathInfo {
    location: {
        is: (term:string) => boolean;
        url: string;
    };
    isAdminView: boolean;
    isSignupOrSignin: boolean;
    isAcceptingInvite: boolean;
}

type HierarchyRecursiveCallback = (obj: OrgHierarchy, index: number) => void | boolean;

export function pageChange(href: any) {
    appActions.setPathRequest(href);
}

export function debug(...stuff: any[]) {
    if (DEBUG) console.log.apply(console, stuff);
}

//import {getWindowPath} from 'history/lib/DOMUtils';
import { colors } from 'lib/teacup/lib/colors';
import { any } from 'async';
export function pathInfo(): IPathInfo {
    let location = '';

    return {
        location: {
            url: location,
            is: (term: string):boolean => {
                return (location.match(new RegExp(term)) !== null);
            }
        },
        isAdminView: (location.match(/\/admin\//) !== null),
        isSignupOrSignin: (location.match(/\/(signup|sign-up|signin|forgot-password)/) !== null),
        isAcceptingInvite: (location.match(/\/invite/) !== null)
    }
}


function __findWhereSubMatch(item: any, subItemToMatch: any) {
    const _keys = Object.keys(subItemToMatch);

    for (let k = 0; k < _keys.length; k++) {
        const targetValue = subItemToMatch[_keys[k]];
        const foundValue   = item[_keys[k]];

        if (foundValue === targetValue) {
            return true;
        }
    }

    return false;
}
function __flattenIncomingArray(_array) {
    let buffer = [];

    if (_array === null || _array === undefined) {
        return [];
    }

    const keys = Object.keys(_array);

    for (let i = 0; i < keys.length; i++) {
        buffer.push({
            ..._array[keys[i]],
            _key: keys[i]
        });
    }

    return buffer;
}
export function findWhere(_array: any, subItemToMatch: any) {
    let array = __flattenIncomingArray(_array);

    let anyMatches = false;

    try {
        for (let i = 0; i < array.length; i++) {
            if (__findWhereSubMatch(array[i], subItemToMatch)) {
                anyMatches = true;
            }
        }
    }
    catch(e) {
        console.error(e);
    }

    return anyMatches;
}

export function findWhereItem(_array: any, subItemToMatch: any) {
    let array = __flattenIncomingArray(_array);

    let anyMatches = [];

    try {
        for (let i = 0; i < array.length; i++) {
            if (__findWhereSubMatch(array[i], subItemToMatch)) {
                anyMatches.push(array[i]);
            }
        }
    }
    catch(e) {
        console.error(e);
    }

    if (anyMatches.length === 1) {
        return anyMatches[0];
    }

    return anyMatches;
}

export function recurseHierarchy(hierarchy: OrgHierarchy[], callback: HierarchyRecursiveCallback<OrgHierarchy, number>, parent: string = null) {
    each(hierarchy, (obj, index) => {
        let hasChildren = (has(obj, 'children')),
            newObj = clone(obj, true),
            group = clone(newObj.group, true);

        if (parent && !has(group, 'parentID')) group['parentID'] = parent;

        newObj.group = group;

        //break out early if we need no further recursion
        if (callback(newObj, index)) return;

        if (hasChildren) recurseHierarchy(obj.children, callback, (hasChildren) ? group.id : null);
    });
}

export function getFirstTeamInOrg(hierarchy: OrgHierarchy): string {
    let teamID = null;

    if (hierarchy && hierarchy.children) {
        recurseHierarchy(hierarchy.children, (ob, index) => {
            if (ob.group.type === 'Team' && index === 0 && !teamID) {
                teamID = ob.group.id;
            }
        });
    }

    return teamID;
}

export function getColorForRole (roleName: string) {
    const colorMap = {
        Administrator:       { color: '#F44336' },
        CohortAdministrator: { color: '#F44336' },
        Instructor:          { color: '#FFEB3B' },
        Mentor:              { color: 'blue' },
        TeamMember:          { color: 'green' },
        Observer:            { color: 'grey' },
        SystemAdministrator: { color: 'grey' }
    };

    return colorMap[roleName];
}

export function getOrgTeamsAndCohorts(org: OrgState): {
    teams: TeamsState,
    cohorts: CohortsState,
    folders: FoldersState
} {
    let admins = {},
        returnData = {
            teams: {},
            cohorts: {},
            folders: {}
        };

    if (has(org, 'roles')) {
        each(org.roles, (groups: {type: string; group: Group}[], userID) => {
            let teamMember = find(org.members, {id: userID});

            each(groups, role => {
                if (role && role.group) {
                    let {type, group} = role;

                    if (!group.members) group.members = {};

                    let groupType = `${group.type.toLowerCase()}s`;

                    if (!returnData[groupType][group.id]) returnData[groupType][group.id] = group;
                    returnData[groupType][group.id].members[userID] = userID;
                }
            });
        });
    }


    if (has(org.hierarchy, 'children')) {
        recurseHierarchy(org.hierarchy.children, (obj, index) => {
            let {group, children} = obj,
                groupType = `${group.type.toLowerCase()}s`,
                existingGroup = (returnData[groupType][group.id]) ? returnData[groupType][group.id] : null,
                teams = (children && group.type === 'Cohort') ? children.map(ob => { return ob.group.id; }) : [];

            if (!existingGroup) group['members'] = {};

            if (group.type === 'Cohort') group['teams'] = teams;

            returnData[groupType][group.id] = (existingGroup) ? merge(existingGroup, group) : group;
        });
    }

    return returnData;
}

export function getNumberOfPeriods(startDate: Date, endDate: Date, weekEndDay: string = 'Sunday') {
    let periods = Math.ceil((endDate - startDate)/(1000*60*60*24*7));

    if (endDate.getDay() !== weekDays.indexOf(weekEndDay)) periods++;

    return periods;
}

// Centralize the format we use for displaying dates
export function getDateFormat() {
    return "MMM DD YYYY";
}

const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

function daysAsTime(days: number = 1) {
  return days * 86400000;
}

export function calculatePeriod(startDate: Date, endDate: Date, period: number = 1, weekEndDay: string = 'Sunday'): DateRange {
    if (period > getNumberOfPeriods(startDate, endDate, weekEndDay)) return new Error('period is out of range');

    let endDay = weekDays.indexOf(weekEndDay);
    let startDay = startDate.getDay();
    let dayAdjustment = startDay !== endDay ? endDay - startDay : 0;

    let periodStart = new Date(startDate);
    let periodEnd = new Date();

    let adjustment = dayAdjustment;

    if (period > 1) {
        adjustment = adjustment + (period - (adjustment < 0 ? 1 : 2)) * 7;

        periodStart.setTime(periodStart.getTime() + daysAsTime(adjustment));
        periodEnd.setTime(periodStart.getTime() + daysAsTime(7));

        if (periodEnd.getTime() > endDate.getTime()) {
            periodEnd.setTime(endDate.getTime());
        }
    } else {
        if (adjustment < 0) adjustment = adjustment + 7;
        periodEnd.setTime(periodStart.getTime() + daysAsTime(adjustment));
    }

    periodStart.setHours(0, 0, 0, 0);
    periodEnd.setHours(0, 0, 0, 0);

    return { start: periodStart.toUTCString(), end: periodEnd.toUTCString() };
}

// Use the path of the browser to determine if we are currently in the
// presentation view
export function isInPresentationMode(user: User, cohort: CohortState) {

    // Hide the secondary effects of presentation mode if the user is a team member
    if (userHasSubRole('TeamMember', user, cohort)) {
        if (!userHasRole('Instructor', user, cohort)) {
            return false;
        }
    }

    const currentPathInfo = pathInfo();

    // Make sure our path contains presentation
    // But ISN'T (e.g. containing a -) a sub presentation 
    // path
    if (currentPathInfo.location.is('\/presentation') && 
        !currentPathInfo.location.is('\/presentation-')) {
        return true;
    }

    return false;

}

// Check to see if any chat windows are open on the BMC
export function isAnyChatOpen(group: TeamState): boolean {
    if (!group) return false;

    if (group.commentHoverTarget === '') return false;
    if (group.commentHoverTarget === undefined) return false;

    return true;
}

// Figure out the resulting date of a given group + a specificed number
// of days
// This is used in day based periods
export function getDateFromStart(group: Group, days: number) {
    if (!group) return undefined;
    if (!days) return undefined;
    
    const startDate: Date = new Date(group.created);

    let endDate: Date = new Date(group.created);

    endDate.setDate(startDate.getDate() + days);

    return endDate;
}

// Get the date in days back from the end
// so 4 days behind an end date of January 4th is January 1st
export function getDateFromEnd(group: Group, days: number) {
    if (!group) return undefined;
    if (!days) return undefined;
    
    const startDate: Date = new Date(group.created);

    let endDate: Date = new Date(group.created);

    endDate.setDate(endDate.getDate() - days);

    return endDate;
}

export function daysInGroup(group: Group) {
    if (!group) return 0;
    
    const endDate: Date = new Date();
    const startDate: Date = new Date(group.created);

    const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 

    return diffDays;
}

export function getDateRanges(group: Group) {
    //start ranges with blank start and end to negate 0 index TODO: shouldn't correct this way, -1 should be calculated where necessary
    let ranges:DateRange[] = [{start:null, end:null}];

    let endDate: Date = new Date();
    let startDate: Date = new Date(group.created);

    // TODO: this should be processed in the action or store that loads cohorts (dates should be dates not strings)
    if (group && group.endDate) endDate = new Date(group.endDate);
    if (group && group.startDate) startDate = new Date(group.startDate);

    let numberOfPeriods = getNumberOfPeriods(startDate, endDate, group.weekEndDay);

    for (var i = 1; i <= numberOfPeriods; i++) {
        ranges.push(calculatePeriod(startDate, endDate, i, group.weekEndDay));
    }

    return ranges;
}

export function canUserSeePresentations(user: User, team: TeamState, cohort: CohortState) {
    // Ensure we have all our necessary checks
    if (user === undefined || team === undefined || cohort === undefined) return false;

    // Check for a null-case on this cohort
    if (cohort === null) return false;

    // Also ensure it's enabled
    if (cohort && cohort.presentationEnabled !== 1) return false;

    const isInstructor = userHasRole('Instructor', user, cohort);
    const isTeamMember = userHasRole("TeamMember", user, team);

    if (isInstructor || user.isAdmin) {
        return true;
    }
    else {
        // If it's supported, check for team member access
        if (cohort && cohort.canMembersViewPresentations == 1) {
            if (isTeamMember) {
                return true;
            }
        }
    }

    return false;
}

export function userHasRole(role: string, user: User, team: TeamState | CohortState | string) {
    if (!user || !team) return false;

    let teamID = typeof team === 'string' ? team : team.id;

    if (user.roles && user.roles[teamID]) {
        if (role === '*') return true;

        let buffer = false;

        for (let i = 0; i < user.roles[teamID].length; i++) {
            if (user.roles[teamID][i].title === role) {
                buffer = true;
            }
        }

        return buffer;
    }

    return false;
}

/** determine if a cohort has a subgroup with a role for the user */
export function userHasSubRole(role: string, user: User, group: CohortState) {
    // Check for a supplied null group
    if (group === null || group === undefined) {
        return false;
    }

    return some(group.teams, teamID => userHasRole(role, user, teamID));
}
